<script lang="ts" setup>
import navItems from '@/navigation/horizontal'

import { themeConfig } from '@themeConfig'

// Components
import NavBarNotifications from '@/layouts/components/NavBarNotifications.vue'
import Reload from '@/layouts/components/Reload.vue'
import UserProfile from '@/layouts/components/UserProfile.vue'
import Footer from '@/views/front-pages/front-page-footer.vue'
import { HorizontalNavLayoutLanding } from '@layouts'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'

const isDrawerVisible = ref(false)
const userData = useCookie<any>('userData')
</script>

<template>
  <HorizontalNavLayoutLanding :nav-items="navItems">
    <!-- 👉 navbar -->
    <template #navbar>
      <NuxtLink
        :to="{ name: 'index' }"
        class="app-logo d-flex align-center gap-x-3"
      >
        <VNodeRenderer :nodes="themeConfig.app.logo" />
      </NuxtLink>
      <VSpacer />

      <Reload />

      <div class="d-flex">
        <VBtn
          to="/about"
          variant="plain"
          color="#2F2B3D"
        >
          <VIcon
            icon="tabler-home-question"
            class="d-sm-none"
          />
          <span class="d-none d-sm-block">場所とる.comとは</span>
        </VBtn>

        <VBtn
          v-if="!userData"
          :to="{ name: 'contact' }"
          variant="plain"
          color="#2F2B3D"
        >
          <VIcon
            icon="tabler-mail"
            class="d-sm-none"
          />
          <span class="d-none d-sm-block">お問い合わせ</span>
        </VBtn>

        <div
          v-if="userData"
          class="d-flex align-center"
        >
          <VBtn
            to="/help"
            variant="plain"
            color="#2F2B3D"
          >
            <VIcon
              size="26"
              icon="tabler-user-plus"
              class="d-sm-none"
            />
            <span class="d-none d-sm-block ms-2">ヘルプ</span>
          </VBtn>

          <NuxtLink
            v-if="userData.role !== 'admin'"
            :to="{ name: userData.role === 'client' ? 'clients-calendar' : 'owners-calendar' }"
          >
            <IconBtn>
              <VIcon
                size="26"
                icon="tabler-calendar"
              />
            </IconBtn>
          </NuxtLink>
          <NavBarNotifications />
          <UserProfile v-model:isDrawerOpen="isDrawerVisible" />
        </div>
        <div
          v-else
          class="d-flex gap-x-4"
        >
          <NuxtLink :to="{ name: 'member' }">
            <VBtn
              variant="outlined"
              color="primary"
              rounded="pill"
            >
              <VIcon
                icon="tabler-user-plus"
                class="d-sm-none"
              />
              <span class="d-none d-sm-block ms-2">無料会員登録</span>
            </VBtn>
          </NuxtLink>

          <NuxtLink :to="{ name: 'login' }">
            <VBtn
              variant="elevated"
              rounded="pill"
              color="primary"
            >
              <VIcon
                icon="tabler-login"
                class="d-sm-none"
              />
              <span class="d-none d-sm-block ms-2">ログイン</span>
            </VBtn>
          </NuxtLink>
        </div>
      </div>
    </template>

    <!-- 👉 Pages -->
    <slot />

    <!-- 👉 Footer -->
    <template #footer>
      <Footer />
    </template>

    <TheCustomizer v-model:isDrawerOpen="isDrawerVisible" />
  </HorizontalNavLayoutLanding>
</template>
